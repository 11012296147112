export const AZURE_LOGIN = 'azureLogin'
export const CHANGE_LOCALE = 'changeLocale'
export const IMPERSONATION = 'impersonation'
export const CREDENTIALS_LOGIN = 'credentialsLogin'
export const LOGOUT = 'logout'
export const RESET = 'reset'
export const UPDATE_RULES = 'UPDATE_RULES'
export const SECOND_FACTOR_LOGIN = '2FA'
export const TRIAL_OPT_IN = 'trialOptIn'

export type TrialOptIn = (input: { registrationId: string, password: string }) => Promise<void>
