import { router } from '@/plugins/router/router'

export const redirectToLogin = () => {
  if (router.currentRoute.name === 'login') {
    return
  }
  const params = {
    redirect: router.currentRoute.fullPath,
    q: router.currentRoute.query.q || undefined
  }

  router.push({
    name: 'login',
    query: params
  })
}

export const redirectToTestAccountExpiredPage = () => {
  router.push({ name: 'trial_error_expired' }).finally()
}
