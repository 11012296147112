export type WindowWithConfig = Window & typeof globalThis & {
  config?: Record<string, string|Array<any>>
}

export function runtimeConfig (name: string, defaultValue?: any) {
  const windowWithConfig = window as WindowWithConfig

  if (windowWithConfig.config && name in windowWithConfig.config) {
    return windowWithConfig.config[name]
  }

  if (name in process.env) {
    return process.env[name]
  }

  if (arguments.length === 2) {
    return defaultValue
  }

  throw new Error(`No runtime configuration "${name}"`)
}

export type PatternTuple = [RegExp, string]
export const searchAndReplace = (object: Record<string, any> | string, patterns: Array<PatternTuple>): Record<string, any>|string => {
  switch (typeof object) {
  case 'string' :
    patterns.forEach(pair => {
      object = object.replace(...pair)
    })
    break
  case 'object':
    if (object instanceof Array) {
      const length = object.length
      for (let i = 0; i < length; i++) {
        object[i] = searchAndReplace(object[i], patterns)
      }
    } else {
      for (const i in object) {
        object[i] = searchAndReplace(object[i], patterns)
      }
    }
    break
  }

  return object
}

export const configKey: Record<string, string> = {
  VUE_APP_TRIAL_ENABLED: 'VUE_APP_TRIAL_ENABLED'
}
