<template>
  <div
    class="industries"
  >
    <div>
      <p class="d-inline">{{ $t('components.microtrend.overlay.industries') }}</p>
      <v-btn
        @click="onClick"
        color="primary"
        class="ma-0 float-right"
        small
        rounded
        icon
        text
      >
        <to-icon>check</to-icon>
      </v-btn>
    </div>
    <div class="pt-3">
      <loading-circle
        v-if="waiting"
        class="pa-3"
        size="30"
      />
      <v-btn-toggle
        v-else
        v-model="internal"
        multiple
      >
        <v-btn
          v-for="item in items"
          :value="item.id"
          :key="item.id"
          small
        >
          {{ item.title }}
        </v-btn>
      </v-btn-toggle>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { GET_LOCALE } from '@/store/auth/getter-types'
import { LIST } from '@/model/views/modes'
import { MICROTRENDS } from '@/model/types'

import industriesGQL from '@/graphql/app/microtrend/query/industries.gql'

import LoadingCircle from '@/components/loading-circle/loading-circle'
import ToIcon from '@/components/to-icon/to-icon'

const authStore = namespace('auth')

@Component({
  apollo: {
    industries: {
      query: industriesGQL,
      variables () {
        return {
          locale: this.locale
        }
      }
    }
  },
  components: {
    LoadingCircle,
    ToIcon
  }
})
export default class IndustriesEdit extends Vue {
  @Prop({
    type: Object,
    validator: obj => MICROTRENDS.includes(obj.__typename)
  }) microtrend

  @Prop({ type: Array }) value

  @Prop({
    type: Boolean,
    default: () => false
  }) pending

  @Prop({
    type: String,
    required: true
  }) mode

  @authStore.Getter(GET_LOCALE) locale

  industries = []

  get items () {
    return this.industries
      ? this.industries.map(elem => ({
        id: elem.id,
        title: elem.translations[0].title
      }))
      : []
  }

  get waiting () {
    return this.pending || this.$apollo.queries.industries.loading
  }

  get internal () {
    return this.value
  }

  set internal (value) {
    this.$emit('input', value)
  }

  onClick () {
    this.$emit('submit')
  }

  @Watch('pending')
  onChangePending (n) {
    if (n === false && this.mode !== LIST) {
      this.$emit('change-mode', LIST)
    }
  }
}
</script>
