import { runtimeConfig } from '@/util/runtime-config'
import Vue from 'vue'
import store from '@/store'
import { namespacer } from '@/util/namespacer'
import { GET } from '@/store/flag/getter-types'
import { COOKIE_CONSENT, MATOMO } from '@/model/flags'
import { GET_TRACKING_ID } from '@/store/auth/getter-types'
import { AccountType } from '@/graphql/app/types'

export class Tracker {
  private readonly enabled: boolean = false

  constructor (enabled: boolean) {
    this.enabled = enabled
  }

  setCompanyId (companyTitle: string) {
    this.setCustomVariable(1, 'COMPANY ID', companyTitle)
  }

  setAccountType (accountType: AccountType): void {
    this.setCustomVariable(2, 'ACCOUNT TYPE', accountType.toString())
  }

  setModule (module: string): void {
    this.setCustomVariable(3, 'MODULE', module)
  }

  private setCustomVariable (index: number, name: string, value: string): void {
    this.submit(['setCustomVariable', index, name, value])
  }

  setTrackingId (trackingId: string) {
    if (store.getters[namespacer('flag', GET)]({ type: COOKIE_CONSENT, name: MATOMO })) {
      this.submit(['setUserId', trackingId])
    }
  }

  setCookieConsentGiven (given: boolean): void {
    if (given) {
      this.submit(['setCookieConsentGiven'])
      this.submit(['setUserId', store.getters[namespacer('auth', GET_TRACKING_ID)]])

      return
    }

    this.submit(['resetUserId'])
    this.submit(['forgetCookieConsentGiven'])
  }

  setTrackingConsentGiven (given: boolean): void {
    if (given) {
      this.submit(['setConsentGiven'])

      return
    }

    this.submit(['forgetConsentGiven'])
  }

  trackEvent (category: string, event: string, name?: string, value?: number) {
    if (!this.enabled) {
      console.warn('Tracking is disabled')

      return
    }

    const trackingInput = ['trackEvent', category, event]

    if (name) {
      trackingInput.push(name)
    }

    if (value) {
      trackingInput.push(value.toString())
    }

    this.submit(trackingInput)
  }

  trackPageView (path: string, title: string, location: string): void {
    this.submit(['setDocumentTitle', location + path])
    this.submit(['trackPageView'])
  }

  private submit (item: Array<string|number>): void {
    // @ts-ignore
    window._paq.push(item)
  }

  resetTrackingId (): void {
    this.submit(['resetUserId'])
  }
}

const tracker = new Tracker(runtimeConfig('VUE_APP_MATOMO_TRACKING_ENABLED', '0') === '1')
const track = (category: string, event: string, name?: string, value?: number) => tracker.trackEvent(category, event, name, value)
const trackPageView = (path: string, title: string, location: string) => tracker.trackPageView(path, title, location)
const setCompanyForTracking = (companyTitle: string) => tracker.setCompanyId(companyTitle)
const setAccountTypeForTracking = (accountType: AccountType) => tracker.setAccountType(accountType)
const setModuleForTracking = (product: string) => tracker.setModule(product)
const resetTrackingId = () => tracker.resetTrackingId()
const setTrackingId = (trackingId: string) => tracker.setTrackingId(trackingId)

const plugin = {
  // @ts-ignore
  install: (app) => {
    app.prototype.$track = track
    app.prototype.$trackPageView = trackPageView
  }
}

Vue.use(plugin, {})

export { tracker, track, setCompanyForTracking, setAccountTypeForTracking, setModuleForTracking, setTrackingId, resetTrackingId }
