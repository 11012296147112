export const MICROTRENDS = 'microtrends'
export const MACROTRENDS = 'macrotrends'
export const MEGATRENDS = 'megatrends'

export const TRENDRADARS = 'trendradars'
export const PARTICIPATIONS = 'participations'

export const TRENDCALLS = 'trendcalls'
export const TRENDING_TOPICS = 'trending-topics'
export const TREND_SUMMARIES = 'trend-summaries'

export const INSIGHTS_TABS = [
  TRENDCALLS, TRENDING_TOPICS, TREND_SUMMARIES
]
