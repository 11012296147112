import { loadPage, pageFilterProps } from '@/plugins/router/utils'
import { initFilter as initialInnovationProjectFilter } from '@/model/innovation-project/filter'
import { initFilter as initialInnovationFieldFilter } from '@/model/innovation-field/filter'
import { ssoEnabled } from '@/mixins/azure-sso'

import AdminLayout from '@/layouts/admin'
import MenuLayout from '@/layouts/menu'

import { ROLE_ADMIN, ROLE_TREND_MANAGER } from '@/model/roles'
import * as TAB from '@/model/views/tabs'
import * as VIEW from '@/model/views/ids'
import { configKey, runtimeConfig } from '@/util/runtime-config'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: loadPage('login/login'),
    props: (route) => ({
      redirect: route.query.redirect,
      q: route.query.q
    })
  },
  {
    path: '/2fa',
    name: '2fa',
    component: loadPage('login/second-factor'),
    props: (route) => ({
      hash: route.params.hash,
      target: route.params.target,
      email: route.params.email
    })
  },
  {
    path: '/impersonation',
    name: 'impersonation',
    component: loadPage('login/impersonation'),
    props: (route) => ({
      redirect: route.query.redirect,
      q: route.query.q
    })
  },
  {
    path: '/password-reset/request',
    name: 'password-reset-request',
    component: loadPage('login/password/request')
  },
  {
    path: '/password-reset/:token/submit',
    name: 'password-reset-submit',
    component: loadPage('login/password/submit'),
    props: (route) => ({
      token: route.params.token
    })
  },
  {
    path: '/',
    component: MenuLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'company-megatrend/create',
        name: 'company-megatrend-create',
        component: loadPage('company-megatrend/create'),
        meta: {
          requiresRole: ROLE_TREND_MANAGER
        }
      },
      {
        path: 'company-megatrend/:id/edit',
        name: 'company-megatrend-edit',
        component: loadPage('company-megatrend/edit'),
        props: true,
        meta: {
          requiresRole: ROLE_TREND_MANAGER
        }
      },
      {
        path: 'company-macrotrend/create',
        name: 'company-macrotrend-create',
        component: loadPage('company-macrotrend/create'),
        meta: {
          requiresRole: ROLE_TREND_MANAGER
        }
      },
      {
        path: 'company-macrotrend/:id/edit',
        name: 'company-macrotrend-edit',
        component: loadPage('company-macrotrend/edit'),
        props: true,
        meta: {
          requiresRole: ROLE_TREND_MANAGER
        }
      },
      {
        alias: '',
        path: 'dashboard',
        name: 'dashboard',
        component: loadPage('dashboard/dashboard'),
        props: (route) => ({
          token: route.params.token || null
        })
      },
      {
        path: 'innovation-projects',
        name: 'innovation-projects',
        component: loadPage('innovation-project/index/index'),
        props: (route) => pageFilterProps(route, initialInnovationProjectFilter()),
        meta: {
          view: VIEW.INNOVATION_PROJECTS
        }
      },
      {
        path: 'innovation-projects/create',
        name: 'innovation-project-create',
        component: loadPage('innovation-project/create/create')
      },
      {
        path: 'innovation-projects/:id/view',
        name: 'innovation-project-view',
        component: loadPage('innovation-project/view/view'),
        props: (route) => ({
          id: route.params.id
        })
      },
      {
        path: 'innovation-projects/:id/edit',
        name: 'innovation-project-edit',
        component: loadPage('innovation-project/edit/edit'),
        props: (route) => ({
          id: route.params.id
        })
      },

      {
        path: '/trendradar',
        name: 'trendradar',
        redirect: to => ({
          name: 'trendradar-trendradars',
          params: to.params,
          query: to.query
        })
      },

      {
        path: '/trendradar/radars',
        name: 'trendradar-trendradars',
        component: loadPage('trendradar/index/index'),
        props: (route) => ({
          page: route.query.page ? Number.parseInt(route.query.page) : 1,
          tab: TAB.TRENDRADARS
        }),
        meta: {
          authorizeTXCompany: true
        }
      },
      {
        path: '/trendradar/participations',
        name: 'trendradar-participations',
        component: loadPage('trendradar/index/index'),
        props: (route) => ({
          page: route.query.page ? Number.parseInt(route.query.page) : 1,
          tab: TAB.PARTICIPATIONS
        }),
        meta: {
          authorizeTXCompany: true
        }
      },
      {
        path: 'trendradar/:trendradarId/view',
        name: 'trendradar-view',
        component: loadPage('trendradar/view/view'),
        props: (route) => ({
          trendradarId: route.params.trendradarId
        }),
        meta: {
          authorizeTXCompany: true
        }
      },
      {
        path: 'trendradar/:participationId/participation',
        name: 'trendradar-participation',
        component: loadPage('evaluation/index/index'),
        props: (route) => ({
          participationId: route.params.participationId
        }),
        meta: {
          authorizeTXCompany: true
        }
      },
      {
        path: 'trendradar/:participationId/evaluate/:evaluationId',
        name: 'trendradar-participation-evaluation',
        component: loadPage('evaluation/participation/participation'),
        props: (route) => ({
          participationId: route.params.participationId,
          evaluationId: route.params.evaluationId
        })
      },

      {
        path: 'trendradar/:trendradarDraftId/dashboard',
        name: 'trendradar-dashboard',
        component: loadPage('trendradar/dashboard'),
        props: (route) => ({
          id: route.params.trendradarDraftId
        })
      },

      {
        path: 'company-microtrend/create',
        name: 'company-microtrend-create',
        component: loadPage('company-microtrend/create/create')
      },
      {
        path: 'company-microtrend/:id/edit',
        name: 'company-microtrend-edit',
        component: loadPage('company-microtrend/edit/edit'),
        props: true
      },

      {
        path: 'trendfinder',
        name: 'trendfinder',
        redirect: to => ({
          name: 'trendfinder-microtrends',
          query: to.query
        })
      },

      {
        path: 'trendfinder/microtrends',
        name: 'trendfinder-microtrends',
        component: loadPage('trendfinder/trendfinder'),
        props: (route) => ({
          page: route.query.page ? Number.parseInt(route.query.page) : 1,
          tab: TAB.MICROTRENDS
        }),
        meta: {
          view: VIEW.TRENDFINDER_MICROTRENDS
        }
      },
      {
        path: 'trendfinder/macrotrends',
        name: 'trendfinder-macrotrends',
        component: loadPage('trendfinder/trendfinder'),
        props: (route) => ({
          page: route.query.page ? Number.parseInt(route.query.page) : 1,
          tab: TAB.MACROTRENDS
        }),
        meta: {
          view: VIEW.TRENDFINDER_MACROTRENDS
        }
      },
      {
        path: 'trendfinder/megatrends',
        name: 'trendfinder-megatrends',
        component: loadPage('trendfinder/trendfinder'),
        props: (route) => ({
          page: route.query.page ? Number.parseInt(route.query.page) : 1,
          tab: TAB.MEGATRENDS
        }),
        meta: {
          view: VIEW.TRENDFINDER_MEGATRENDS
        }
      },

      {
        name: 'macrotrend-detail',
        path: 'trendfinder/macrotrends/:macrotrendId/details',
        component: loadPage('trendfinder/macrotrend/details'),
        props: (route) => {
          return {
            macrotrendId: route.params.macrotrendId,
            radar: route.query.radar || null,
            category: route.query.category || null
          }
        }
      },
      {
        name: 'macrotrend-detail-old',
        path: 'trendfinder/macrotrends/:macrotrendId',
        redirect: to => {
          return {
            name: 'macrotrend-detail',
            query: to.query,
            params: to.params
          }
        }
      },
      {
        name: 'megatrend-detail',
        path: 'trendfinder/megatrends/:megatrendId',
        component: loadPage('trendfinder/megatrend/view'),
        props: (route) => ({
          megatrendId: route.params.megatrendId
        })
      },
      {
        path: 'innovation-fields',
        name: 'innovation-fields',
        component: loadPage('innovation-field/index/index'),
        props: (route) => pageFilterProps(route, initialInnovationFieldFilter()),
        meta: {
          view: VIEW.INNOVATION_FIELDS
        }
      },
      {
        path: 'innovation-fields/:innovationFieldId/detail',
        name: 'innovation-field-detail',
        component: loadPage('innovation-field/view/view'),
        props: (route) => ({
          innovationFieldId: route.params.innovationFieldId
        })
      },
      {
        path: 'innovation-fields/create',
        name: 'innovation-field-create',
        component: loadPage('innovation-field/create/create')
      },
      {
        path: 'innovation-fields/:innovationFieldId/edit',
        name: 'innovation-field-edit',
        component: loadPage('innovation-field/edit/edit'),
        props: (route) => ({
          innovationFieldId: route.params.innovationFieldId
        })
      },
      {
        path: 'workspaces',
        name: 'workspace',
        component: loadPage('workspace/index/index'),
        props: (route) => ({
          page: route.query.page ? Number.parseInt(route.query.page) : 1
        }),
        meta: {
          view: VIEW.WORKSPACES
        }
      },
      {
        path: 'workspaces/:workspaceId/detail',
        name: 'workspace-detail',
        component: loadPage('workspace/view/view'),
        props: (route) => ({
          workspaceId: route.params.workspaceId
        })
      },
      {
        path: 'insights',
        name: 'insights',
        redirect: { name: 'insights-trendcalls' }
      },
      {
        path: 'workspaces/:workspaceId/search-agent/:searchAgentId/detail',
        name: 'search-agent-detail',
        component: loadPage('search-agent/view/view'),
        props: (route) => ({
          workspaceId: route.params.workspaceId,
          searchAgentId: route.params.searchAgentId
        })
      },
      {
        path: 'insights/trending-topics/:id',
        name: 'insights-trending-topic-detail',
        component: loadPage('insights/trending-topic/view'),
        props: true
      },
      {
        path: 'insights/trendcalls',
        name: 'insights-trendcalls',
        component: loadPage('insights/index/index'),
        meta: {
          view: VIEW.INSIGHTS_TRENDCALLS
        },
        props: (route) => ({
          page: route.query.page || 1,
          tab: TAB.TRENDCALLS
        })
      },
      {
        path: 'insights/trending-topics',
        name: 'insights-trending-topics',
        component: loadPage('insights/index/index'),
        meta: {
          view: VIEW.INSIGHTS_TRENDING_TOPICS
        },
        props: (route) => ({
          page: route.query.page || 1,
          tab: TAB.TRENDING_TOPICS
        })
      },
      {
        path: 'insights/trend-summaries',
        name: 'insights-trend-summaries',
        component: loadPage('insights/index/index'),
        meta: {
          view: VIEW.INSIGHTS_TREND_SUMMARIES
        },
        props: (route) => ({
          page: route.query.page || 1,
          tab: TAB.TREND_SUMMARIES
        })
      },
      {
        path: 'insights/trend-summary/:id',
        name: 'insights-trend-summary-detail',
        component: loadPage('insights/trend-summary/view'),
        props: true
      },
      {
        path: 'profile',
        name: 'profile',
        component: loadPage('profile/index')
      }
    ]
  },
  {
    path: '/admin',
    component: AdminLayout,
    meta: {
      requiresAuth: true,
      requiresRole: ROLE_TREND_MANAGER
    },
    children: [
      {
        path: '',
        component: loadPage('company-admin/index'),
        alias: 'dasboard',
        name: 'admin-dashboard'
      },
      {
        path: 'company-trends',
        component: loadPage('company-admin/company-trends'),
        name: 'admin-company-trend',
        props: (route) => ({
          showMacrotrendCreate: route.params.showMacrotrendCreate || false,
          showMegatrendCreate: route.params.showMegatrendCreate || false
        })
      },
      {
        path: 'trendone-universe',
        component: loadPage('company-admin/trendone-universe'),
        name: 'admin-trendone-universe'
      },
      {
        path: 'user',
        component: loadPage('company-admin/company-user'),
        name: 'admin-user-index',
        meta: {
          requiresAuth: true,
          requiresRole: ROLE_ADMIN
        }
      }
    ]
  },
  {
    path: '/missing-feature',
    component: loadPage('error/error'),
    name: 'missing-feature-redirect',
    props: true
  },
  {
    path: '*',
    component: loadPage('error/error'),
    name: 'error',
    props: true
  }
]

export const addSSORoutesIfenabled = () => {
  if (ssoEnabled()) {
    routes.unshift({
      path: '/azure-login',
      name: 'azure-login',
      component: loadPage('login/azure-login'),
      props: (route) => ({
        code: route.query.code,
        error: route.query.error || null,
        errorDescription: route.query.error_description || null
      })
    })
  }
}

export const addTrialRoutes = () => {
  const enabled = runtimeConfig(configKey.VUE_APP_TRIAL_ENABLED, 'not defined') === '1'
  if (!enabled) {
    return
  }

  const trialRoutes = [
    {
      path: '/trial/registration/:locale',
      name: 'trial_registration',
      component: loadPage('trial/registration/registration'),
      props: true
    },
    {
      path: '/trial/thank-you/:locale',
      name: 'trial_thankYou',
      component: loadPage('trial/registration/thank-you'),
      props: true
    },
    {
      path: '/trial/opt-in/:registrationId/:locale',
      name: 'trial_optin',
      component: loadPage('trial/opt-in'),
      props: true
    },
    {
      path: '/trial/note/opt-in',
      name: 'trial_error_optIn',
      component: loadPage('trial/note/opt-in')
    },
    {
      path: '/trial/note/expired',
      name: 'trial_error_expired',
      component: loadPage('trial/note/expired')
    }
  ]

  trialRoutes.forEach(route => {
    routes.unshift(route)
  })
}

addSSORoutesIfenabled()
addTrialRoutes()

export default routes
