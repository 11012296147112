import * as ID from './ids'
import * as COL from './columns'
import i18n from '@/plugins/i18n'

export const DEFAULTS = {
  [ID.INSIGHTS_TRENDCALLS]: [
    {
      component: COL.IMAGE,
      label: 'components.trendcall.list.columns.image',
      align: 'center',
      sortable: false
    },
    {
      component: COL.TITLE,
      label: 'components.trendcall.list.columns.title',
      sortable: true
    },
    {
      component: COL.DATE,
      label: 'components.trendcall.list.columns.date',
      sortable: true
    },
    {
      component: COL.AUTHOR,
      label: 'components.trendcall.list.columns.author',
      sortable: false
    }
  ],
  [ID.INSIGHTS_TRENDING_TOPICS]: [
    {
      component: COL.IMAGE,
      label: 'components.trendingTopic.list.columns.image',
      align: 'center',
      sortable: false
    },
    {
      component: COL.TITLE,
      label: 'components.trendingTopic.list.columns.title',
      sortable: true
    },
    {
      component: COL.DATE,
      label: 'components.trendingTopic.list.columns.date',
      sortable: true
    },
    {
      component: COL.AUTHOR,
      label: 'components.trendingTopic.list.columns.author',
      sortable: false
    }
  ],
  [ID.INSIGHTS_TREND_SUMMARIES]: [
    {
      component: COL.IMAGE,
      label: 'components.trendSummary.list.columns.image',
      align: 'center',
      sortable: false
    },
    {
      component: COL.TITLE,
      label: 'components.trendSummary.list.columns.title',
      sortable: true
    },
    {
      component: COL.DATE,
      label: 'components.trendSummary.list.columns.date',
      sortable: true
    },
    {
      component: COL.AUTHOR,
      label: 'components.trendSummary.list.columns.author',
      sortable: false
    }
  ],
  [ID.INNOVATION_FIELDS]: [
    {
      component: COL.IMAGE,
      label: 'components.innovationField.list.columns.image',
      sortable: false
    },
    {
      component: COL.TITLE,
      label: 'components.innovationField.list.columns.title',
      sortable: true,
      mandatory: true
    },
    {
      component: COL.UPDATED_AT,
      label: 'components.innovationField.list.columns.updatedAt',
      sortable: true
    }
  ],
  [ID.INNOVATION_PROJECTS]: [
    {
      component: COL.IMAGE,
      label: 'components.innovationProject.list.columns.image',
      sortable: false
    },
    {
      component: COL.TITLE,
      label: 'components.innovationProject.list.columns.title',
      sortable: true,
      mandatory: true
    },
    {
      component: COL.UPDATED_AT,
      label: 'components.innovationProject.list.columns.updatedAt',
      sortable: true
    },
    {
      component: COL.STATE,
      label: 'components.innovationProject.list.columns.status',
      align: 'center',
      sortable: true
    }
  ],
  [ID.TRENDFINDER_MACROTRENDS]: [
    {
      component: COL.IMAGE,
      label: 'components.macrotrend.list.columns.image',
      sortable: false
    },
    {
      component: COL.TITLE,
      label: 'components.macrotrend.list.columns.title',
      mandatory: true,
      sortable: true
    }
  ],
  [ID.TRENDFINDER_MEGATRENDS]: [
    {
      component: COL.IMAGE,
      label: 'components.megatrend.list.columns.image',
      sortable: false
    },
    {
      component: COL.TITLE,
      label: 'components.megatrend.list.columns.title',
      sortable: true,
      mandatory: true
    }
  ],
  [ID.TRENDFINDER_MICROTRENDS]: [
    {
      component: COL.IMAGE,
      label: 'components.microtrend.list.columns.image',
      sortable: false
    },
    {
      component: COL.TITLE,
      label: 'components.microtrend.list.columns.title',
      sortable: true,
      mandatory: true
    },
    {
      component: COL.IDENTIFIED_AT,
      label: 'components.microtrend.list.columns.identifiedAt',
      sortable: true
    },
    {
      component: COL.AUTHOR,
      label: 'components.microtrend.list.columns.author',
      sortable: false
    }
  ],
  [ID.TRENDRADAR_RADARS]: [
    {
      component: COL.IMAGE,
      label: 'components.trendradar.list.columns.image',
      sortable: false
    },
    {
      component: COL.TITLE,
      label: 'components.trendradar.list.columns.title',
      sortable: true,
      mandatory: true
    },
    {
      component: COL.UPDATED_AT,
      label: 'components.trendradar.list.columns.updatedAt',
      sortable: true
    }
  ],
  [ID.TRENDRADAR_PARTICIPATIONS]: [
    {
      component: COL.IMAGE,
      label: 'components.evaluation.list.columns.image',
      sortable: false
    },
    {
      component: COL.TITLE,
      label: 'components.evaluation.list.columns.title',
      sortable: true,
      mandatory: true
    },
    {
      component: COL.UPDATED_AT,
      label: 'components.evaluation.list.columns.updatedAt',
      sortable: true
    }
  ],
  [ID.WORKSPACES]: [
    {
      component: COL.IMAGE,
      label: 'components.workspace.list.columns.image',
      sortable: false
    },
    {
      component: COL.TITLE,
      label: 'components.workspace.list.columns.title',
      sortable: true,
      mandatory: true
    },
    {
      component: COL.UPDATED_AT,
      label: 'components.workspace.list.columns.updatedAt',
      sortable: true
    },
    {
      component: COL.PARTCIPANTS,
      label: 'components.workspace.list.columns.participants',
      sortable: false
    }
  ]
}

export const AVAILABLES = {
  [ID.INSIGHTS_TREND_SUMMARIES]: [
    ...DEFAULTS[ID.INSIGHTS_TREND_SUMMARIES]
  ],
  [ID.INSIGHTS_TRENDCALLS]: [
    ...DEFAULTS[ID.INSIGHTS_TRENDCALLS]
  ],
  [ID.INSIGHTS_TRENDING_TOPICS]: [
    ...DEFAULTS[ID.INSIGHTS_TRENDING_TOPICS]
  ],
  [ID.INNOVATION_FIELDS]: [
    ...DEFAULTS[ID.INNOVATION_FIELDS],
    {
      component: COL.MEGATRENDS,
      label: 'components.innovationField.list.columns.megaTrends',
      sortable: false
    },
    {
      component: COL.MACROTRENDS,
      label: 'components.innovationField.list.columns.macroTrends',
      sortable: false
    }
  ],
  [ID.INNOVATION_PROJECTS]: [
    ...DEFAULTS[ID.INNOVATION_PROJECTS],
    {
      component: COL.MEGATRENDS,
      label: 'components.innovationProject.list.columns.megatrends',
      sortable: false
    },
    {
      component: COL.MACROTRENDS,
      label: 'components.innovationProject.list.columns.macrotrends',
      sortable: false
    }
  ],
  [ID.TRENDFINDER_MACROTRENDS]: [
    ...DEFAULTS[ID.TRENDFINDER_MACROTRENDS]
  ],
  [ID.TRENDFINDER_MEGATRENDS]: [
    ...DEFAULTS[ID.TRENDFINDER_MEGATRENDS]
  ],
  [ID.TRENDFINDER_MICROTRENDS]: [
    ...DEFAULTS[ID.TRENDFINDER_MICROTRENDS],
    {
      component: COL.INITIATORS,
      label: 'components.microtrend.list.columns.initiator',
      sortable: false
    },
    {
      component: COL.COUNTRY,
      label: 'components.microtrend.list.columns.country',
      sortable: false,
      align: 'center'
    },
    {
      component: COL.MEGATRENDS,
      label: 'components.microtrend.list.columns.megatrends',
      sortable: false
    },
    {
      component: COL.MACROTRENDS,
      label: 'components.microtrend.list.columns.macrotrends',
      sortable: false
    },
    {
      component: COL.INDUSTRY,
      label: 'components.microtrend.list.columns.industry',
      sortable: false
    },
    {
      component: COL.INNOVATION_TYPE,
      label: 'components.microtrend.list.columns.innovationType',
      sortable: false
    }
  ],
  [ID.TRENDRADAR_RADARS]: [
    ...DEFAULTS[ID.TRENDRADAR_RADARS],
    {
      component: COL.MANAGERS,
      label: 'components.trendradar.list.columns.managers',
      sortable: false
    }
  ],
  [ID.TRENDRADAR_PARTICIPATIONS]: [
    ...DEFAULTS[ID.TRENDRADAR_PARTICIPATIONS]
  ],
  [ID.WORKSPACES]: [
    ...DEFAULTS[ID.WORKSPACES],
    {
      component: COL.TREND_COUNT,
      label: 'components.workspace.list.columns.microtrendCount',
      sortable: false,
      align: 'right'
    },
    {
      component: COL.UNVOTED_COUNT,
      label: 'components.workspace.list.columns.unvotedCount',
      sortable: false,
      align: 'right'
    },
    {
      component: COL.NEW_COUNT,
      label: 'components.workspace.list.columns.newCount',
      sortable: false,
      align: 'right'
    }
  ]
}

export const availables = (id) => AVAILABLES[id].map(elem => {
  return {
    ...elem,
    value: elem.value || elem.component
  }
})

export const tableHeaders = (id) => AVAILABLES[id].map(elem => ({
  text: i18n.t(elem.label),
  component: elem.component,
  value: elem.value || elem.component,
  sort: elem.sort || undefined,
  sortable: elem.sortable,
  align: elem.align || 'left',
  tooltip: elem.tooltip || null
}))

export const availableTableHeaders = (available) => available.map(elem => ({
  text: i18n.t(elem.label),
  component: elem.component,
  value: elem.value || elem.component,
  sort: elem.sort || undefined,
  sortable: elem.sortable,
  align: elem.align || 'left',
  tooltip: elem.tooltip || null
}))
