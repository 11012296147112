// types
export const UI = 'ui'
export const COOKIE_CONSENT = 'cookieConsent'
export const TRACKING_CONSENT = 'trackingConsent'
export const TRIAL = 'trial'

// UI names
export const HAS_SEEN_UNIVERSE_DIALOG = 'hasSeenUniverseDialog'
export const HAS_SEEN_WORKSPACE_DEPRECATION_NOTICE = 'hasSeenWorkspaceDeprecationNotice'
export const HAS_SEEN_INNOVATION_FIELD_DEPRECATION_NOTICE = 'hasSeenInnovationFieldDeprecationNotice'
export const HAS_SEEN_INNOVATION_PROJECT_DEPRECATION_NOTICE = 'hasSeenInnovationProjectDeprecationNotice'
export const HAS_SEEN_TREND_RADAR_DEPRECATION_NOTICE = 'hasSeenTrendRadarDeprecationNotice'
export const HAS_SEEN_OVERRIDE_CONFIRMATION_PROMPT = 'hasSeenOverrideConfirmationPrompt'
export const HAS_SEEN_TRIAL_OPT_IN = 'hasSeenTrialOptIn'

// TRACKING_CONSENT names
export const MATOMO = 'matomo'

// COOKIE_CONSENT names
export const AUTHENTICATION = 'authentication'
export const ACTIVELY_CONSENTED = 'activelyConsented'
